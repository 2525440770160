exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-templates-about-page-js": () => import("./../../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-cart-page-jsx": () => import("./../../../src/templates/cart-page.jsx" /* webpackChunkName: "component---src-templates-cart-page-jsx" */),
  "component---src-templates-contact-page-js": () => import("./../../../src/templates/contact-page.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-creations-page-js": () => import("./../../../src/templates/creations-page.js" /* webpackChunkName: "component---src-templates-creations-page-js" */),
  "component---src-templates-home-page-js": () => import("./../../../src/templates/home-page.js" /* webpackChunkName: "component---src-templates-home-page-js" */),
  "component---src-templates-message-page-js": () => import("./../../../src/templates/message-page.js" /* webpackChunkName: "component---src-templates-message-page-js" */),
  "component---src-templates-product-page-js": () => import("./../../../src/templates/product-page.js" /* webpackChunkName: "component---src-templates-product-page-js" */),
  "component---src-templates-shop-page-js": () => import("./../../../src/templates/shop-page.js" /* webpackChunkName: "component---src-templates-shop-page-js" */)
}

